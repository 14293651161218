import { IMedia } from './media.type'

export enum EScanRecordType {
  General = 'general',
  Impact = 'impact',
  Movement = 'movement',
}

export interface IScan {
  id: string
  scanner_user_email: string
  scanner_user_name: string
  scanner_image_small: string
  record_type: EScanRecordType
  account_tx: string
  user_tx: string
  batch_id: string
  created_at: Date
  note: string
  within_geofence: boolean
  media: Array<IMedia>
  location_info: { locations: IScanLocationsInfo[] } | null
  media_info: { media: IScanMediaInfo[] } | null
  scanner_info: IScanScannerInfo
  network: EScanNetwork
}

export interface IScanMediaInfo {
  link: string
  link_small: string | null
  link_medium: string | null
  link_large: string | null
  type: string
  title: string
  hash: string | null
}

export interface IScanLocationsInfo {
  location_name: string
  location_address: string | null
  latitude?: number
  longitude?: number
  radius: number
  within_geofence?: boolean
}

export interface IScanScannerInfo {
  name: string
  email: string
  wallet: string
  latitude: number
  longitude: number
}

export enum EScanNetwork {
  BASE = 'base',
  POLYGON = 'polygon',
}
