import { EScanNetwork } from 'src/types'

export function getPolygonscanTxUrl(txHash: string) {
  return `${getBaseUrl()}/tx/${txHash}`
}

export function getPolygonscanAddressUrl(address: string) {
  return `${getBaseUrl()}/address/${address}`
}

function getBaseUrl() {
  return process.env.REACT_APP_EXPLORER_BASE_URL
}

export function getScanExplorerTxUrl(txHash: string, network: EScanNetwork) {
  return `${getScanExplorerBaseUrl(network)}/tx/${txHash}`
}

function getScanExplorerBaseUrl(network: EScanNetwork) {
  switch (network) {
    case EScanNetwork.BASE:
      return process.env.REACT_APP_BASE_EXPLORER_BASE_URL
    case EScanNetwork.POLYGON:
      return process.env.REACT_APP_POLYGON_EXPLORER_BASE_URL
    default:
      return null
  }
}
