import { Box, useTheme, IconButton, Divider, Typography, useMediaQuery } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from 'react-i18next'
import { Close as CloseIcon } from '@mui/icons-material'

import { BlockchainModalItem } from '../blockchain-modal-item'
import { MediaSection } from '../scan-media-section'
import { CustomModalAnimated } from 'src/components/modal-animated'
import { BlockchainVerifiedModalHeader } from './header'
import { BlockchainMapItem } from '../blockchain-map-item'

import { formatDate, getPolygonscanAddressUrl, getScanExplorerTxUrl } from 'src/utils'
import { IScan } from 'src/types'
import { ExternalLinkIcon } from 'src/assets/icons/v2/ExternalLinkIcon'
import { COLORS } from 'src/styles/common'

import 'swiper/css'
import { AvatarImg } from 'src/components/step-info/step-company.styled'

interface IProps {
  open: boolean
  handleClose: () => void
  handlePrev: (scanLength: number) => void
  handleNext: (scanLength: number) => void
  currentIndex: number
  scans: IScan[]
  setCurrentIndex: Dispatch<SetStateAction<number>>
  primaryColor?: string
  walletAddress: string
  showWalletAddress: boolean
  accountImage?: string
  scanImage?: string
  scanName?: string
  impactImage?: string
  impactName?: string
}

// const maskEmail = (email: string) => {
//   const [localPart, domain] = email.split('@')
//   const maskedLocalPart = '&#8727;'.repeat(localPart.length)
//   return (
//     <span>
//       <span dangerouslySetInnerHTML={{ __html: maskedLocalPart }} />
//       {`@${domain}`}
//     </span>
//   )
// }

const checkGeofenceStatus = (locations: { within_geofence?: boolean }[]): boolean => {
  return locations && locations.some((location) => location?.within_geofence)
}

export const BlockchainVerifiedModal = ({
  open,
  handleClose,
  handlePrev,
  handleNext,
  currentIndex,
  scans = [],
  setCurrentIndex,
  primaryColor,
  walletAddress,
  showWalletAddress,
  accountImage,
  scanImage,
  scanName,
  impactImage,
  impactName,
}: IProps) => {
  const [t] = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSlideChange = (swiper: any) => {
    setCurrentIndex(swiper.realIndex)
  }

  const truncateAddress = (addr: string) => `${addr.slice(0, 12)}...${addr.slice(-4)}`

  const headerHeight = () => {
    let height = 185

    if (!showWalletAddress) {
      height -= 25
    }

    if (scans.length === 1 && showWalletAddress) {
      height -= 50
    } else if (scans.length === 1 && !showWalletAddress) {
      height -= 20
    }

    return height
  }

  return (
    <CustomModalAnimated ariaLable="modal-scan" open={open} handleClose={handleClose}>
      <Box
        sx={{
          zIndex: 101,
        }}
      >
        <Swiper
          onSlideChange={handleSlideChange}
          slidesPerView={1}
          centeredSlides={true}
          autoHeight={true}
          loop={true}
          spaceBetween={'50px'}
          style={{
            width: '100vw',
            height: '100dvh',
            maxHeight: '100dvh',
          }}
        >
          <BlockchainVerifiedModalHeader
            slot="container-start"
            currentIndex={currentIndex}
            handleNext={handleNext}
            handlePrev={handlePrev}
            scanLength={scans.length}
            primaryColor={primaryColor}
            walletAddress={walletAddress}
            showWalletAddress={showWalletAddress}
            accountImage={accountImage}
          />

          {scans.map((scan) => (
            <SwiperSlide
              key={scan.id}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  maxWidth: '800px',
                  width: '100%',
                  margin: '0 auto',
                  height: '100%',
                  maxHeight: `calc(100dvh - ${headerHeight()}px)`,
                  overflowY: 'auto',
                  padding: '20px 32px',
                }}
              >
                <Typography
                  sx={{ pb: '20px', fontSize: '16px', fontWeight: 700, textAlign: 'center' }}
                >
                  {formatDate(scan.created_at)}
                </Typography>

                {scan?.scanner_info && scan.scanner_info && (
                  <Divider sx={{ my: '10px', borderStyle: 'dashed', borderWidth: '1px' }} />
                )}

                {scan?.scanner_info && scan.scanner_info && (
                  <Typography sx={{ pb: '10px', fontSize: '16px', fontWeight: 600 }}>
                    Scanner details:
                  </Typography>
                )}

                {scan?.scanner_info && scan.scanner_info?.name && (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px', pb: '5px' }}>
                    {scan?.scanner_image_small && (
                      <Box
                        sx={{
                          backgroundImage: `url(${scan.scanner_image_small})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          borderRadius: '50%',
                          width: '45px',
                          height: '45px',
                        }}
                      />
                    )}
                    <BlockchainModalItem
                      value={scan.scanner_info.name}
                      containerSx={{ pb: '0px' }}
                    />
                  </Box>
                )}

                {scan?.scanner_info &&
                  (scan.scanner_info.latitude !== 0 || scan.scanner_info.longitude !== 0) && (
                    <BlockchainModalItem
                      label={'Location'}
                      value={
                        <BlockchainMapItem
                          // locations={scan.location_info.locations}
                          scannerInfo={scan.scanner_info}
                          primaryColor={primaryColor}
                        />
                      }
                      containerSx={{ pb: '5px' }}
                    />
                  )}

                {scan?.scanner_info && scan.scanner_info?.email && (
                  <BlockchainModalItem label={'Email'} value={scan.scanner_info.email} />
                )}

                {scan?.scanner_info &&
                  (scan.scanner_info.latitude !== 0 || scan.scanner_info.longitude !== 0) &&
                  scan.location_info &&
                  scan.location_info.locations &&
                  checkGeofenceStatus(scan.location_info.locations) && (
                    <BlockchainModalItem
                      label={t('blockchain-verified-modal-geofence-lable')}
                      value={'Within Geofence'}
                    />
                  )}

                {scan?.scanner_info && scan.scanner_info?.wallet && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      maxWidth: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: '5px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: 600,
                          color: COLORS.GREY_LIGHT,
                        }}
                      >
                        {'Scanner address'}:
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: '10px',
                          color: COLORS.GREY_LIGHT,
                        }}
                      >
                        {isMobile
                          ? truncateAddress(scan.scanner_info.wallet)
                          : scan.scanner_info.wallet}
                      </Typography>
                    </Box>
                    <IconButton
                      component="a"
                      href={getPolygonscanAddressUrl(scan.scanner_info.wallet)}
                      target="_blank"
                      sx={{
                        p: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ExternalLinkIcon size={16} color={COLORS.GREY_LIGHT} />
                    </IconButton>
                  </Box>
                )}

                {scan?.scanner_info && scan.scanner_info && (
                  <Divider sx={{ my: '10px', borderStyle: 'dashed', borderWidth: '1px' }} />
                )}

                {scan?.scanner_info && scan.scanner_info && (
                  <Typography sx={{ pb: '10px', fontSize: '16px', fontWeight: 600 }}>
                    Scan details:
                  </Typography>
                )}

                {scanName && (
                  <Box sx={{ pb: '10px', display: 'flex', alignItems: 'center', gap: '15px' }}>
                    {scanImage && (
                      <AvatarImg
                        borderRadius={'6px'}
                        width={'65px'}
                        src={scanImage}
                        alt="Scan Image"
                      />
                    )}
                    <Typography sx={{ fontSize: '16px' }}>{scanName}</Typography>
                  </Box>
                )}

                {impactName && (
                  <Box sx={{ pb: '10px', display: 'flex', alignItems: 'center', gap: '15px' }}>
                    {impactImage && (
                      <AvatarImg
                        borderRadius={'6px'}
                        width={'65px'}
                        src={impactImage}
                        alt="Scan Image"
                      />
                    )}
                    <Typography sx={{ fontSize: '16px' }}>{impactName}</Typography>
                  </Box>
                )}

                {scan.batch_id && (
                  <BlockchainModalItem
                    label={t('blockchain-verified-modal-batch-id-lable')}
                    value={scan.batch_id}
                  />
                )}
                {scan?.note && (
                  <BlockchainModalItem
                    label={t('blockchain-verified-modal-note-lable')}
                    value={scan.note}
                    column
                  />
                )}
                {scan?.media_info?.media && scan.media_info.media.length > 0 && (
                  <MediaSection media={scan.media_info.media} />
                )}

                {scan?.account_tx && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '6px',
                      pb: '20px',
                      maxWidth: '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'baseline',
                        gap: '6px',
                        maxWidth: 'calc(100% - 35px)',
                        justifyContent: 'start',
                      }}
                    >
                      <Typography
                        sx={{ fontSize: '12px', fontWeight: 600, color: COLORS.GREY_LIGHT }}
                      >
                        {t('blockchain-verified-modal-tx-lable')}:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: COLORS.GREY_LIGHT,
                        }}
                      >
                        {isMobile ? (
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {scan.account_tx.slice(0, -4)}
                            </Typography>

                            <Typography sx={{ ml: '-3px' }}>{scan.account_tx.slice(-4)}</Typography>
                          </Box>
                        ) : (
                          scan.account_tx
                        )}
                      </Typography>
                    </Box>
                    <IconButton
                      component="a"
                      href={getScanExplorerTxUrl(scan.account_tx, scan.network)}
                      target="_blank"
                      sx={{ p: '3px', width: '26px' }}
                    >
                      <ExternalLinkIcon size={20} color={COLORS.GREY_LIGHT} />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'fixed',
            top: 4,
            right: 8,
            zIndex: 100,
            color: theme.palette.common.black,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </CustomModalAnimated>
  )
}
