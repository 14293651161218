import Carousel from 'react-material-ui-carousel'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Button } from '@mui/material'

import { IMedia } from 'src/types'

import { ImageSt, VideoSt, navIcon } from './image-carousel.styled'

interface IProps {
  images?: Array<IMedia>
  isStep?: boolean
}

export const ImageCarousel = ({ images, isStep }: IProps) => {
  const media = images.filter(
    (item) => item.type.startsWith('image') || item.type.startsWith('video'),
  )

  return (
    <>
      <Carousel
        animation="slide"
        indicators={media.length > 1}
        autoPlay={false}
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={media.length == 1}
        NavButton={({ onClick, className, style, next, prev }) => {
          const handleClick = () => {
            onClick()
          }
          return (
            <div
              style={{
                width: '60px',
                marginInline: '-10px',
                cursor: 'pointer',
                height: isStep ? '267px' : '178px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleClick}
            >
              <Button
                className={className}
                sx={{
                  width: 40,
                  height: 40,
                  minWidth: 0,
                  margin: '0px',
                  backgroundColor: 'black',
                  opacity: 0,
                  transition: 'all 0.3s ease-in-out',
                }}
                onClick={handleClick}
                style={style}
              >
                {next && <NavigateNext className={navIcon} />}
                {prev && <NavigateBefore className={navIcon} />}
              </Button>
            </div>
          )
        }}
      >
        {media.map((item) => {
          if (item.type.startsWith('video')) {
            return (
              <VideoSt
                key={item.id}
                src={item.link}
                controls
                autoPlay
                playsInline
                muted
                style={{ height: isStep ? '267px' : '178px' }}
              />
            )
          }

          return (
            <ImageSt
              key={item.id}
              alt={item.title}
              src={item.link_large || item.link}
              style={{ height: isStep ? '267px' : '178px' }}
            />
          )
        })}
      </Carousel>
    </>
  )
}
